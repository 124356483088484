import AntButton from 'antd/lib/button';

export default function Button({ children, ariaLabel, ...rest }: any) {
    const classList = ['efButton']; //结合style使用
    // const classList = [css.efButton]
    const props = {
        'aria-label': ariaLabel || 'Ecoflow',
        ...rest,
    };
    if (rest.className) {
        classList.push(rest.className);
        props.className = classList;
    }
    return (
        <>
            <AntButton className="inside-btn" {...props} style={{ cursor: 'pointer' }}>
                <span>{children}</span>
            </AntButton>
        </>
    );
}
