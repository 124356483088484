import { Input as AntInput } from 'antd';
import css from './input.module.scss';

const Input = ({ children, ...rest }: any) => {
    const classList = [css.efInput];
    const props = { ...rest };
    if (rest.className) {
        classList.push(rest.className);
        props.className = classList.join(' ');
    }
    return <AntInput {...props} />;
};
Input.TextArea = AntInput.TextArea;
Input.Search = AntInput.Search;
Input.Password = AntInput.Password;

export default Input;
