// a: 方案a, b: 方案b, null: 不使用方案
interface StoreProps {
    plan: 'a' | 'b' | 'c';
    expired: number;
    privacyUrl: string;
    pluginUrl: string;
    isStatic?: string;
}
type LoadMethod = 'async' | 'defer' | undefined | null | '';

const KEY = 'kaamel_store';
const kaamel = {
    a: {
        privacyUrl: 'https://eu-trust-center-a.ecoflow.com/privacy-policy.html',
        pluginUrl: 'https://eu-trust-center-a.ecoflow.com/static/js/kaamel.js-1.9.6/kaamel.js?t=20230324',
    },
    b: {
        privacyUrl: 'https://eu-trust-center-b.ecoflow.com/privacy-policy.html',
        pluginUrl: 'https://eu-trust-center-b.ecoflow.com/static/js/kaamel.js-1.9.6/kaamel.js?t=20230324',
    },
};

// store是否过期
const isExpired = (date: number) => date < Date.now();

// 存储store
export const recordStore = (store: StoreProps) => {
    localStorage.setItem(KEY, JSON.stringify(store));
};

// 通过crypto生成随机数
const getRandomValue = () => {
    const crypto = window.crypto || window.webkitCrypto || window.mozCrypto || window.oCrypto || window.msCrypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    return array[0] / (Math.pow(2, 32) - 1);
};

// 生成方案
const generateKaamelPlan = () => {
    const randomValue = getRandomValue();
    const store: StoreProps = {
        expired: Date.now() + 259200000,
        plan: 'c',
        privacyUrl: '',
        pluginUrl: '',
    };

    if (randomValue < 1 / 3) {
        store.plan = 'a';
        Object.assign(store, {...kaamel['a']});
    } else if (randomValue < 2 / 3) {
        store.plan = 'b';
        Object.assign(store, {...kaamel['b']});
    }

    recordStore(store);

    return store;
};

// 获取kaamel方案
export const getKaamelPlan = () => {
    const info = localStorage.getItem(KEY);
    if (info) {
        try {
            const res = JSON.parse(info);
            const overdue = isExpired(res.expired);

            if (overdue) {
                return generateKaamelPlan();
            } else {
                return res;
            }
        } catch (err) {}
    } else {
        return generateKaamelPlan();
    }
};
